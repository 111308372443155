<template>
    <v-container fluid>

        <!-- Section Path File/Modul -->
        <v-breadcrumbs 
            :items="pathModul"
            divider="-"
            normal
            class="pathModul"
        ></v-breadcrumbs>

        <!-- Section Filtering Input/Select/Button -->
        <v-container fluid :class="$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1'">


            <v-row align="center" v-if="currentMode.a == true && currentMode.m == true">
                <v-col
                cols="2"
                class="col-input-filter"
                >
                    <v-select
                    :items="listMode"
                    v-model="modeSelected"
                    label="Sampling Type"
                    hide-details
                    solo
                    dense
                    @change="changeMode()"
                    ></v-select>
                </v-col>
            </v-row>

            <v-row align="center">

                <!-- Column Select Station -->
                <v-col
                v-show="currentMode.is == false"
                class="col-input-filter"
                >
                    <v-autocomplete
                    v-on:change="selectValueStation"
                    :items="itemStation"
                    label="Station"
                    prepend-inner-icon="mdi-store-marker"
                    hide-details
                    solo
                    dense
                    ></v-autocomplete>
                </v-col>

                <!-- Column Select Sample ID -->
                <v-col
                v-show="currentMode.is == true"
                class="col-input-filter"
                >
                    <v-select
                    :items="itemSample"
                    v-model="sampleIDSelected"
                    label="Sample ID"
                    prepend-inner-icon="mdi-store-marker"
                    hide-details
                    solo
                    dense
                    ></v-select>
                </v-col>

                <!-- Column Select Calendar From-->
                <v-col
                class="col-input-filter"
                >
                    <v-menu
                    v-model="modalCalendar"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="dateFrom"
                        prefix="From :"
                        prepend-inner-icon="mdi-calendar-start"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        solo
                        dense
                        >
                        </v-text-field>
                    </template>
                    <v-date-picker
                        v-model="dateFrom"
                        @input="modalCalendar = false"
                    >
                        <v-spacer></v-spacer>
                    </v-date-picker>
                    </v-menu>
                </v-col>

                <!-- Column Select Calendar To-->
                <v-col
                class="col-input-filter"
                >
                    <v-menu
                    v-model="modalCalendar2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="dateTo"
                        prefix="To :"
                        prepend-inner-icon="mdi-calendar-end"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        solo
                        dense
                        >
                        </v-text-field>
                    </template>
                    <v-date-picker
                        v-model="dateTo"
                        @input="modalCalendar2 = false"
                    >
                        <v-spacer></v-spacer>
                    </v-date-picker>
                    </v-menu>
                </v-col>

                <!-- Column Generate Button -->
                <v-col cols="12" sm="6" md="4">
                    <v-btn
                    class="white--text button mt-3 mr-2"
                    solo dense normal
                    :disabled="modeSelected != 'Investigate Study'? valStation == null? true : false : sampleIDSelected == ''? true : false"
                    @click="generateTable()"
                    >
                        GENERATE REPORT
                    </v-btn>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            color="excel"
                            class="mt-3"
                            style="float: right;"
                            elevation="2"
                            v-bind="attrs"
                            v-on="on"
                            :disabled="tbody_rawData.length == 0"
                            @click="exportReport('excel')"
                            >
                                <v-icon
                                color="white"
                                center
                                dark
                                >
                                    mdi-microsoft-excel
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Export to Excel</span>
                    </v-tooltip>
                
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            color="csv"
                            class="mt-3 mx-2"
                            style="float: right;"
                            elevation="2"
                            v-bind="attrs"
                            v-on="on"
                            @click="exportCSV()"
                            :disabled="tbody_rawData.length == 0"
                            >
                                <v-icon
                                color="white"
                                center
                                dark
                                >
                                    mdi-file-delimited
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Export to CSV</span>
                    </v-tooltip>
                </v-col>
            </v-row>    

            <v-card class="pa-4">
                <v-subheader
                class="subTitle black--text font-weight-bold pa-0"
                >
                   <center> RAW DATA FOR ({{valStation}}) FROM {{this.convertDateFormat(dateFrom)}} TO {{this.convertDateFormat(dateTo)}}</center>
                </v-subheader>

                <!-- Form Table -->
                <div>
                    <!-- Table -->
                    <v-data-table

                    v-model = "tableRaw"
                    :headers="thead_rawData"
                    :items="tbody_rawData"
                    class="elevation-1 tableRawData headerDtSarawak"
                    :items-per-page="itemPerPage"
                    fixed-header
                    :header-props="{ sortIcon: null }"
                    :loading="loadingTable"
                    >

                    <!-- <template v-slot:[`item.DATETIME`]="{ item }">
                        d{{ useConvertDisplayDatetime(item.DATETIME)}}
                    </template> -->


                    <!-- <template v-slot:[`item.WQI`]="{ item }">
                        <v-chip
                        :color="getColor(item.WQI)"
                        dark
                        :class="getFontColor(item.WQI)"
                        >
                            {{ item.WQI }}
                        </v-chip>
                    </template> -->

                    <!-- <template v-slot:[`item.DO_SAT`]="{ item }">
                        <span v-if=" item.DO_SAT_MIN == null && item.DO_SAT_MAX == null ">{{ item.DO_SAT }}</span>
                        <span v-else-if=" item.DO_SAT >= item.DO_SAT_MIN && item.DO_SAT <= item.DO_SAT_MAX ">{{ item.DO_SAT }}</span>
                        <span class="red--text" v-else>{{ item.DO_SAT }}</span>
                    </template> -->

                    <template
                    v-slot:body="{ items }"
                    >

                        <div
                        v-if="items.length == 0 && thead_rawData.length != 0"
                        class="pa-2 text-body-2"
                        style="white-space: nowrap;"
                        >
                            No Data Available
                        </div>

                        <tbody>
                        <tr
                        v-for="(item,x) in items"
                        :key="x"
                        >
                            <td v-for="(itemtd,i) in thead_rawData" :key="i" class="text-center">
                                
                                <span v-if="itemtd.value == 'DATETIME'">
                                    <span v-html="useConvertDisplayDatetimeR(item[itemtd.value])" ></span>
                                </span>

                                <span v-else-if="itemtd.value == 'STATION_ID' || itemtd.value == 'SAMPLE_ID' || itemtd.value == 'LOCATION'">
                                    {{item[itemtd.value]}}
                                </span>
                                
                                <span v-else>
                                    <span v-if="item[itemtd.value+'_MIN'] == null && item[itemtd.value+'_MAX'] == null ">{{ item[itemtd.value] }}</span>
                                    <span v-else-if="item[itemtd.value] >= item[itemtd.value+'_MIN'] && item[itemtd.value] <= item[itemtd.value+'_MAX'] ">{{ item[itemtd.value] }}</span>
                                    <span class="red--text" v-else>{{ item[itemtd.value] }}</span>
                                </span>

                            </td>
                        </tr>
                        </tbody>
                    </template>


                    </v-data-table>

                    <p v-if="modeSelected == 'Continuous'" style="font-size:0.90em;text-align:left;">
                        <b>
                            Note: Red text indicated that value has exceeded above maximum & minimum value
                        </b>
                    </p>
                

                </div>
            </v-card>

        </v-container>

    </v-container>
</template>


<script>

import axios from 'axios';
import Papa from "papaparse";

export default {

    
    data: () => ({

        GlobalDataBody: [],
        dataParams: [],

        pathModul: [
            {
                text: 'Home',
                disabled: false,
                // href: '/',
                href: '/Mapviewer',
            },
            {
                text: 'Report - Raw Data',
                disabled: true,
                href: '/Report/RawData',
            },
        ],
        itemStation: [
          // 'CMS_1 - Bakun HEP',
          // 'CMS_2 - Rh.Apan,Long Menjawah',
          // 'CMS_3 - Kapit Water Intake',
          // 'CMS_4 - Rh. Bawai, Baleh'
        ],
        itemSample: [],
        sampleIDSelected: "",
        dateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modalCalendar: false,
        menu2: false,
        modalCalendar2: false,

        valStation: null,
        listParam: [],

        // Table
        loadingTable: false,
        loadingText: "",
        expIcon: null,
        thead_rawData: [],
        headerDatetimeAM : {
          text: 'Datetime',
          sortable: true,
          value: 'DATETIME',
          divider: true,
          align: 'center',
        },
        headerDatetimeIS : {
          text: 'Record Date & Time',
          sortable: true,
          value: 'DATETIME',
          divider: true,
          align: 'center',
        },
        headerStation : {
          text: 'Station ID',
          sortable: true,
          value: 'STATION_ID',
          divider: true,
          align: 'center',
        },
        headerSampleID : {
          text: 'Sample ID',
          sortable: true,
          value: 'SAMPLE_ID',
          divider: true,
          align: 'center',
        },
        headerLocation : {
          text: 'Location',
          sortable: true,
          value: 'LOCATION',
          divider: true,
          align: 'center',
        },
        headerCoordinate : {
          text: 'Coordinate',
          sortable: true,
          value: 'COORDINATE',
          divider: true,
          align: 'center',
        },
        firstLoadtbody_rawData: [],
        tbody_rawData: [],
        tableRaw: [],
        itemPerPage: 10,


        listMode: [],
        currentMode: {},
        modeSelected: "",

        
    }),

    mounted() {
      
        this.getCurrentMode();

        this.getListSampleID();
        
        // this.getParamStation();

    },

    methods: {

        useConvertDisplayDatetimeR(dt){

            let val = dt;
            let date;

            date = this.convertDateFormat(val.split(" ")[0])  + "<br>" + this.convert12Hours(val.split(" ")[1]);

            return date;

        },

        getCurrentMode(){

            if(localStorage.clickButtonAMIS == undefined){
                this.modeSelected = "Continuous"
            }
            else{

                this.currentMode = JSON.parse(localStorage.clickButtonAMIS)

                // console.log(this.currentMode);

                if(this.currentMode.a == true && this.currentMode.m == false){
                    this.modeSelected = "Continuous"
                }
                else if(this.currentMode.a == false && this.currentMode.m == true){
                    this.modeSelected = "Manual"
                }
                else if(this.currentMode.a == true && this.currentMode.m == true){
                    this.listMode = ["Continuous","Manual"]
                    this.modeSelected = "Continuous"
                }
                else if(this.currentMode.is == true){
                    this.modeSelected = "Investigate Study"
                }

            }

            this.getListStation(this.modeSelected);

        },

        changeMode(){
            this.getListStation(this.modeSelected);
            this.thead_rawData = [];
            this.tbody_rawData = [];
        },

        convert12Hours(time) {
            var H = +time.substr(0, 2);
            var h = H % 12 || 12;
            var ampm = (H < 12 || H === 24) ? "AM" : "PM";
            time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;
            return time;
        },

        convertDateFormat(date){
            var dd = date.split("-")[2];
            var mm = date.split("-")[1];
            var yyyy = date.split("-")[0];
            date = dd + "/" + mm + "/" + yyyy;
            return date;
        },

        getListSampleID(){

            this.itemSample = [];

            axios.get(this.globalUrl+'mqm2/miqims/allsampleiddropdown_rawdata_invstudy', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                
                let data = response.data;

                for(let i in data){
                    this.itemSample.push(data[i].SAMPLE_ID)
                }
                
                
            })
            .catch(error => {
                console.log(error);
            })

        },

        getListStation(mod){

            this.itemStation = [];
            this.valStation = null

            let urlStations = ""
            if(mod == "Continuous"){
                urlStations = "mqm2/miqims/stations"
            }
            else if(mod == "Manual"){
                urlStations = "mqm2/miqims/stations_manual"
            }
            else if(mod == "Investigate Study"){
                urlStations = "mqm2/miqims/stations_invstudy"
            }

            axios.get(this.globalUrl+urlStations, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {

                // console.log(response.data);

                for (let i = 0; i < response.data.length; i++) {
                    this.itemStation.push(response.data[i].STATION_ID + " - " + response.data[i].LOCATION);
                }
                
            })
            .catch(error => {
                console.log(error);
            })

        },

        // testtt(){
        //   axios.get('https://apis.spatialworks.com.my/sarawak/cms/params', {
        //         headers: {
        //             'Authorization': 'Bearer ' + this.$store.getters.user.token,
        //         }
        //     })
        //     .then((response) => {
        //         console.log(response.data.length);
                
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     })
        // },

        loadDataQAQC(){

            this.tbody_rawData = [];
            this.expIcon = "1"

            axios.get(this.globalUrl+'mqm2/miqims/qaqc1', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // console.log(response.data);
                let data =  response.data;
                let keyData = Object.keys(data[0])

                // console.log(data);
                // console.log(this.firstLoadtbody_rawData);

                for (let i = 0; i < this.firstLoadtbody_rawData.length; i++) {
                for (let k = 0; k < keyData.length; k++) {
                    // if (keyData[k].includes("STATION_ID")) {

                    // }
                    if (keyData[k].includes("STATION_ID") == false){

                    // this.tbody_rawData[i][keyData[k]] = null;

                    for (let x = 0; x < data.length; x++) {
                        if (this.firstLoadtbody_rawData[i].station_a == data[x].STATION_ID) {
                        // this.tbody_rawData[i][keyData[k]] == data[x][keyData[k]];
                        this.firstLoadtbody_rawData[i][keyData[k]] = data[x][keyData[k]];
                        // console.log(data[x]);
                        }
                    }

                    }
                    
                }
                }

                this.tbody_rawData = this.firstLoadtbody_rawData;
                this.loadingTable = false;
                // this.loadingText = "";

                // console.log(this.tbody_rawData);
                

                document.querySelector(".subTitle").style.display = "unset";


                // console.log(this.tbody_rawData);
                
            })
            .catch(error => {
                console.log(error);
            })
        },

        handler() {
            var args = arguments;
            for (var arg of args) {
            if (arg instanceof Function) {
                arg();
            }
            }
        },

        // Get value from Selected Station
        selectValueStation: function(e) {
            this.valStation = e.split(' - ')[0];
            // console.log(this.valStation);
        },

        getParamHeader(station){

            // console.log(station);

            let urlParams = 'mqm2/miqims/params?stationid='+station+'&subindex=0';
            if(this.modeSelected == "Continuous"){
                urlParams = 'mqm2/miqims/params?stationid='+station+'&subindex=0';
            }
            else if(this.modeSelected == "Manual"){
                urlParams = 'mqm2/miqims/params_manual?stationid='+station+'&subindex=0';
            }
            else if(this.modeSelected == "Investigate Study"){
                urlParams = "mqm2/miqims/params_invstudy";
            }
            

            console.log(this.globalUrl+urlParams);

            axios.get(this.globalUrl+urlParams,{
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                // this.stationDetails = response.data;
                // console.log(response.data);
                // let key = [
                //     'text',
                //     'value',
                //     'divider',
                //     'align'
                // ],
                // result = response.data.map(o => Object.assign(...key.map(k => ({ [k]: o[k] }))));

                // this.listParam.push(result);
                // this.thead_rawData = result;

                let data = response.data;
                this.dataParams = data;

                if(this.modeSelected == "Continuous" || this.modeSelected == "Manual"){
                    this.thead_rawData.unshift(this.headerStation);
                    this.thead_rawData.unshift(this.headerDatetimeAM);
                }
                else if(this.modeSelected == "Investigate Study"){
                    this.thead_rawData.unshift(this.headerDatetimeIS);
                    this.thead_rawData.unshift(this.headerCoordinate);
                    this.thead_rawData.unshift(this.headerSampleID);
                }
                

                // console.log(this.listParam);
                this.loadDataBody();

            })
            .catch(error => {
                console.log(error);
            })
        },

        // Get Data from API Params
        loadDataBody(){

            this.GlobalDataBody = [];
            this.firstLoadtbody_rawData = [];
            this.tbody_rawData = [];

            let urlParams = '';
            if(this.modeSelected == "Continuous"){
                urlParams = 'mqm2/miqims/rawdata?startdt='+ this.dateFrom +'&enddt=' + this.dateTo + '&stationid=all&raw=1';
            }
            else if(this.modeSelected == "Manual"){
                urlParams = 'mqm2/miqims/rawdata_manual?startdt='+ this.dateFrom +'&enddt=' + this.dateTo + '&stationid=all&raw=1';
            }
            else if(this.modeSelected == "Investigate Study"){
                urlParams = 'mqm2/miqims/report_rawdata_invstudy?sampleid=all&startdate=' + this.dateFrom + '&enddate=' + this.dateTo;
            }

            console.log(urlParams);

            axios.get(this.globalUrl+urlParams,{
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {

                let data = response.data;
                console.log(data);

                let keysData = Object.keys(data[0]);
                
                if(data.length != 0){

                    if(this.modeSelected == "Continuous"){

                        this.GlobalDataBody = data;

                        let keysParam = [];
                        for(let i in keysData){
                            if(keysData[i] != 'station_a' && keysData[i] != 'LOCATION'
                            && keysData[i] != 'ID' && keysData[i] != 'STATION_ID' && keysData[i] != 'SAMPLE_ID'
                            && keysData[i] != 'DATETIME' && keysData[i] != 'RECEIVED_DT' && keysData[i] != 'LATITUDE'
                            && keysData[i] != 'LONGITUDE' && keysData[i] != 'TYPE' && (!keysData[i].includes("_PRE"))
                            && (!keysData[i].includes("_CLASS")) && (!keysData[i].includes("_LAB"))){
                                keysParam.push(keysData[i])
                            }
                        }

                        // Create thead for list parameter
                        for(let i in keysParam){
                            for(let x in this.dataParams){
                                if(keysParam[i] == this.dataParams[x].reading){
                                    this.thead_rawData.push({
                                        text: this.dataParams[x].text,
                                        value: keysParam[i],
                                        divider: true,
                                        align: "center",
                                    })
                                }
                            }
                        }

                        let keyData = Object.keys(data[0])
                        for (let i = 0; i < data.length; i++) {
                            

                            data[i]["DATETIME"] = data[i]["DATETIME"] +":00"
                            // data[i]["DATETIME"] = data[i]["DATETIME"].replace(" ","T")
                            // data[i]["DATETIME"] = this.convertDateFormat(data[i]["DATETIME"].split(" ")[0]) 
                            // + "<br>"
                            // + this.convert12Hours(data[i]["DATETIME"].split(" ")[1]);
                            

                        }

                        for (let x = 0; x < data.length; x++) {
                            if (data[x]["station_a"] === this.valStation) {
                            this.firstLoadtbody_rawData.push(data[x])
                            }
                        }

                        if (this.firstLoadtbody_rawData.length != 0) {
                            let myInterval = setInterval(() => {
                                this.loadDataQAQC();
                                clearInterval(myInterval);
                            }, 1000);
                        }
                        else{
                            this.loadingTable = false;
                            this.loadingText = "";
                        }

                    }
                    else if(this.modeSelected == "Manual"){

                        // console.log("manual",data)

                        this.GlobalDataBody = data;

                        let keysParam = [];
                        for(let i in keysData){
                            if(keysData[i] != 'station_a' && keysData[i] != 'LOCATION'
                            && keysData[i] != 'ID' && keysData[i] != 'STATION_ID' && keysData[i] != 'SAMPLE_ID'
                            && keysData[i] != 'DATETIME' && keysData[i] != 'RECEIVED_DT' && keysData[i] != 'LATITUDE'
                            && keysData[i] != 'LONGITUDE' && keysData[i] != 'TYPE' && (!keysData[i].includes("_PRE"))
                            && (!keysData[i].includes("_CLASS")) && (!keysData[i].includes("_LAB"))){
                                keysParam.push(keysData[i])
                            }
                        }

                        // Create thead for list parameter
                        for(let i in keysParam){
                            for(let x in this.dataParams){
                                if(keysParam[i] == this.dataParams[x].reading){
                                    this.thead_rawData.push({
                                        text: this.dataParams[x].text,
                                        value: keysParam[i],
                                        divider: true,
                                        align: "center",
                                    })
                                }
                            }
                        }

                        for(let i in data){

                            let [date,time] = data[i].DATETIME.includes("T")? data[i].DATETIME.split("T") : data[i].DATETIME.split(" ");

                            data[i].DATETIME = this.useConvertDisplayDate(date) + "<br>" + this.useConvert12Hours(time)

                            console.log(data[i].STATION_ID)

                            if(this.valStation == data[i].STATION_ID){
                                this.tbody_rawData.push(data[i])
                            }
                        }

                        // console.log(this.tbody_rawData)

                        this.loadingTable = false;
                    }
                    else if(this.modeSelected == "Investigate Study"){

                        let keysParam = [];
                        for(let i in keysData){
                            if(keysData[i] != 'station_id_b' && keysData[i] != 'LOCATION'
                            && keysData[i] != 'ID' && keysData[i] != 'STATION_ID' && keysData[i] != 'SAMPLE_ID'
                            && keysData[i] != 'DATETIME' && keysData[i] != 'RECEIVED_DT' && keysData[i] != 'LATITUDE'
                            && keysData[i] != 'LONGITUDE'){
                                keysParam.push(keysData[i])
                            }
                        }

                        // Create thead for list parameter
                        for(let i in keysParam){
                            for(let x in this.dataParams){
                                if(keysParam[i] == this.dataParams[x].reading){
                                    this.thead_rawData.push({
                                        text: this.dataParams[x].text,
                                        value: keysParam[i],
                                        divider: true,
                                        align: "center",
                                    })
                                }
                            }
                        }
                    
                        for(let i in data){

                            let [date,time] = data[i].DATETIME.includes("T")? data[i].DATETIME.split("T") : data[i].DATETIME.split(" ");
                            data[i].DATETIME = this.useConvertDisplayDate(date) + "<br>" + this.useConvert12Hours(time)
                            
                            if(data[i]["LATITUDE"] != null && data[i]["LONGITUDE"] != null){
                                data[i]["COORDINATE"] = data[i]["LONGITUDE"] + ", " + data[i]["LATITUDE"]
                            }
                            else{
                                data[i]["COORDINATE"] = "";
                            }

                            if(data[i].SAMPLE_ID == this.sampleIDSelected){
                                this.GlobalDataBody.push(data[i])
                                this.tbody_rawData.push(data[i])
                            }

                        }

                        this.loadingTable = false;

                        // if (this.firstLoadtbody_rawData.length != 0) {
                        //     let myInterval = setInterval(() => {
                        //         this.loadDataQAQC();
                        //         clearInterval(myInterval);
                        //     }, 1000);
                        // }
                        // else{
                        //     this.loadingTable = false;
                        //     this.loadingText = "";
                        // }
                    
                    }
                    
                    
                    // console.log(this.tbody_rawData);

                }
                else{
                    this.loadingTable = false;
                }
                
                

            })
            .catch(error => {
                    console.log(error);
                    this.loadingTable = false;
                    this.loadingText = "";
            })
        },

        // Section Table Function
        generateTable(){

            this.loadingTable = true;
            this.loadingText = "Data Loading... Please wait";

            this.thead_rawData = [];
            this.tbody_rawData = [];
            this.getParamHeader(this.valStation);
            
        },

        // Customize background color for column table for result WQI status
        getColor(wqi) {
            if (wqi > 92.7){
            return '#3486FA'
            }
            else if (wqi > 76.5 && wqi <= 92.7) {
            return '#7AE666'
            }
            else if (wqi >= 50 && wqi <= 76.5) {
            return '#FFFF10'
            }
            else if (wqi >= 31.0 && wqi <= 51.9) {
            return '#FFAB3D'
            }
            else if(wqi >= 1 && wqi <= 30.9){
            return '#FB401D'
            }
            else{
            return 'white'
            }
        },

        // Customize font color for column table for result WQI status
        getFontColor(wqi){
            if (wqi > 92.7){
            return 'white--text'
            }
            else if (wqi > 76.5 && wqi <= 92.7) {
            return 'black--text'
            }
            else if (wqi >= 50 && wqi <= 76.5) {
            return 'black--text'
            }
            else if (wqi >= 31.0 && wqi <= 51.9) {
            return 'white--text'
            }
            else if(wqi >= 1 && wqi <= 30.9){
            return 'white--text'
            }
            else{
            return 'black--text'
            }
        },

        exportCSV(){

            // console.log(this.tableCon)
            // let headerExportCsv = [];
            // for(let i in this.thead_rawData){
            //     headerExportCsv.push(this.thead_rawData[i]['value']);
            // }

            // let keys = headerExportCsv,
            // resultAfterFilter = this.tbody_rawData.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));

            let listKeyHeader = [];
            for(let i in this.thead_rawData){
                listKeyHeader.push(this.thead_rawData[i].value)
            }

            let temptbody = this.tbody_rawData

            let keys = listKeyHeader,
            resultAfterFilter = temptbody.map((o) => Object.assign(...keys.map((k) => ({ [k]: o[k] }))) );
            let dataTbodyOnly = resultAfterFilter;

            let listKeyHeaderFullname = []
            for(let i in dataTbodyOnly){
                for(let x in listKeyHeader){
                    for(let z in this.dataParams){
                        if(listKeyHeader[x] == this.dataParams[z].value){
                            listKeyHeaderFullname.push(this.dataParams[z].text);
                            dataTbodyOnly[i][this.dataParams[z].text] = dataTbodyOnly[i][listKeyHeader[x]]
                            // dataTbodyOnly[i]["Datetime"] = this.useConvertDisplayDatetime(dataTbodyOnly[i]["datetime_a"])
                            dataTbodyOnly[i]["Datetime"] = dataTbodyOnly[i]["DATETIME"]
                            dataTbodyOnly[i]["Station ID"] = dataTbodyOnly[i]["STATION_ID"]
                        }
                    }
                }
            }
            let lastKeys = listKeyHeaderFullname.filter((item,
            index) => listKeyHeaderFullname.indexOf(item) === index);
            lastKeys.unshift("Datetime")
            lastKeys.unshift("Station ID")

            let keyssss = lastKeys,
            lastResults = dataTbodyOnly.map((o) => Object.assign(...keyssss.map((k) => ({ [k]: o[k] }))) );
            let dataToExportCSV = lastResults;

            let blob = new Blob([Papa.unparse(dataToExportCSV)], { type: 'text/csv;charset=utf-8;' });

            let link = document.createElement("a");

            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", 'Raw Data.csv');
            link.style.visibility = 'visible';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        },

        exportReport(){

            // Define your style class template.

            this.itemPerPage = -1;

            let headerName = "<tr = 'border:none !important' ><td colspan ='12' style = 'text-align: center; border:none !important; font-weight: bold'>Raw Data For " + this.valStation + " From " + this.convertDateFormat(this.dateFrom) + " To " + this.convertDateFormat(this.dateTo) + "</td></tr>"
            var style = "<style> th, td { border: 1px solid;}</style>";

            var uri = 'data:application/vnd.ms-excel;base64,'
                , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->' + style + '</head><body><table>' + headerName + '{table}</table></body></html>'
                , base64 = function (s) {
                    return window.btoa(unescape(encodeURIComponent(s)))
                }
                , format = function (s, c) {
                    return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; })
                }

                setTimeout(() => {
        
                    let table = document.querySelectorAll('.v-data-table__wrapper ')
                    // console.log("hehe", table[0].innerHTML, table, document.querySelectorAll('.v-data-table__wrapper table'))

                    let tablei = table[0].innerHTML

                    tablei = tablei.replaceAll("°C","&#176;C").replaceAll("µ","&#181;")

                    let ctx = { worksheet: "Raw Data" || 'Worksheet', table: tablei }
                    // window.location.href = uri + base64(format(template, ctx))

                    let a = document.createElement('a');
                    a.href = uri + base64(format(template, ctx))
                    a.download = 'RawData.xls';
                    //triggering the function
                    a.click();

                    this.itemPerPage = 10;

                }, 1000)
        },


    },
    

}
</script>



<style lang="scss">

    @import '~scss/main';

     /* Section Path File/Modul */
    .pathModul{
      padding-bottom: 0px;
    }

    .pathModul > li:nth-child(3) > a{
      color: black !important;
    }

    /* Section Filtering Input/Select/Button */
    .container-filtering{
      padding: 0px;
    }

    .col-input-filter{
      padding: 0px 10px;
    }

    .col-btn-generateReport{
        margin-bottom: 18px;
    }

    .btn-generate{
        margin: 0px !important;
        // background: #4495D1 !important;
    }


    .v-text-field.v-text-field--solo .v-input__control,
    .btn-generate .v-btn .v-btn--contained{
        min-height: 10px;
        margin-top: 10px;
    }

    /* Section Result Generated Report */
    .container-result-generated{
      background: white;
      padding: 5px 0px;
    }

    .subTitle{
      text-align: center !important;
      background: white;
      // color: #1976D2 !important;
      display: none;
    }

    // .v-data-table-header > tr > th{
    //   color: white !important;
    //   background: #4495D1 !important;
    //   padding: 5px 15px !important;
    // }


    .header-colspan > tr > th{
      text-align: center !important;
      /* border-left:  0.1px solid #e0e0e0; */
      border-right:  0.1px solid #e0e0e0;
      background: #607d8b;
      color: white !important;
    }

    .tableRawData{
      margin: 10px 0px;
      border: 0.1px solid #e0e0e0;
    }

    .tableRawData > div:nth-child(1) > table > tbody > tr > td:nth-child(1){
      white-space: nowrap !important;
    }

    .container-result-generated{
      text-align: center;
    }

    .vChip-text{
      color:black !important;
    }

    .tableRawData > .v-data-table__wrapper{
      max-height: 600px;
    }

    .v-data-table__empty-wrapper > td{
      text-align: left !important;
    }

    /* Footer Report */
    .one-text-footer{
      margin: 0px 10px;
    }

</style>