var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-breadcrumbs',{staticClass:"pathModul",attrs:{"items":_vm.pathModul,"divider":"-","normal":""}}),_c('v-container',{class:_vm.$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1',attrs:{"fluid":""}},[(_vm.currentMode.a == true && _vm.currentMode.m == true)?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"col-input-filter",attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.listMode,"label":"Sampling Type","hide-details":"","solo":"","dense":""},on:{"change":function($event){return _vm.changeMode()}},model:{value:(_vm.modeSelected),callback:function ($$v) {_vm.modeSelected=$$v},expression:"modeSelected"}})],1)],1):_vm._e(),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentMode.is == false),expression:"currentMode.is == false"}],staticClass:"col-input-filter"},[_c('v-autocomplete',{attrs:{"items":_vm.itemStation,"label":"Station","prepend-inner-icon":"mdi-store-marker","hide-details":"","solo":"","dense":""},on:{"change":_vm.selectValueStation}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentMode.is == true),expression:"currentMode.is == true"}],staticClass:"col-input-filter"},[_c('v-select',{attrs:{"items":_vm.itemSample,"label":"Sample ID","prepend-inner-icon":"mdi-store-marker","hide-details":"","solo":"","dense":""},model:{value:(_vm.sampleIDSelected),callback:function ($$v) {_vm.sampleIDSelected=$$v},expression:"sampleIDSelected"}})],1),_c('v-col',{staticClass:"col-input-filter"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prefix":"From :","prepend-inner-icon":"mdi-calendar-start","readonly":"","hide-details":"","solo":"","dense":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalCalendar),callback:function ($$v) {_vm.modalCalendar=$$v},expression:"modalCalendar"}},[_c('v-date-picker',{on:{"input":function($event){_vm.modalCalendar = false}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},[_c('v-spacer')],1)],1)],1),_c('v-col',{staticClass:"col-input-filter"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prefix":"To :","prepend-inner-icon":"mdi-calendar-end","readonly":"","hide-details":"","solo":"","dense":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalCalendar2),callback:function ($$v) {_vm.modalCalendar2=$$v},expression:"modalCalendar2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.modalCalendar2 = false}},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},[_c('v-spacer')],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-btn',{staticClass:"white--text button mt-3 mr-2",attrs:{"solo":"","dense":"","normal":"","disabled":_vm.modeSelected != 'Investigate Study'? _vm.valStation == null? true : false : _vm.sampleIDSelected == ''? true : false},on:{"click":function($event){return _vm.generateTable()}}},[_vm._v(" GENERATE REPORT ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3",staticStyle:{"float":"right"},attrs:{"color":"excel","elevation":"2","disabled":_vm.tbody_rawData.length == 0},on:{"click":function($event){return _vm.exportReport('excel')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","center":"","dark":""}},[_vm._v(" mdi-microsoft-excel ")])],1)]}}])},[_c('span',[_vm._v("Export to Excel")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3 mx-2",staticStyle:{"float":"right"},attrs:{"color":"csv","elevation":"2","disabled":_vm.tbody_rawData.length == 0},on:{"click":function($event){return _vm.exportCSV()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","center":"","dark":""}},[_vm._v(" mdi-file-delimited ")])],1)]}}])},[_c('span',[_vm._v("Export to CSV")])])],1)],1),_c('v-card',{staticClass:"pa-4"},[_c('v-subheader',{staticClass:"subTitle black--text font-weight-bold pa-0"},[_c('center',[_vm._v(" RAW DATA FOR ("+_vm._s(_vm.valStation)+") FROM "+_vm._s(this.convertDateFormat(_vm.dateFrom))+" TO "+_vm._s(this.convertDateFormat(_vm.dateTo)))])],1),_c('div',[_c('v-data-table',{staticClass:"elevation-1 tableRawData headerDtSarawak",attrs:{"headers":_vm.thead_rawData,"items":_vm.tbody_rawData,"items-per-page":_vm.itemPerPage,"fixed-header":"","header-props":{ sortIcon: null },"loading":_vm.loadingTable},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [(items.length == 0 && _vm.thead_rawData.length != 0)?_c('div',{staticClass:"pa-2 text-body-2",staticStyle:{"white-space":"nowrap"}},[_vm._v(" No Data Available ")]):_vm._e(),_c('tbody',_vm._l((items),function(item,x){return _c('tr',{key:x},_vm._l((_vm.thead_rawData),function(itemtd,i){return _c('td',{key:i,staticClass:"text-center"},[(itemtd.value == 'DATETIME')?_c('span',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.useConvertDisplayDatetimeR(item[itemtd.value]))}})]):(itemtd.value == 'STATION_ID' || itemtd.value == 'SAMPLE_ID' || itemtd.value == 'LOCATION')?_c('span',[_vm._v(" "+_vm._s(item[itemtd.value])+" ")]):_c('span',[(item[itemtd.value+'_MIN'] == null && item[itemtd.value+'_MAX'] == null )?_c('span',[_vm._v(_vm._s(item[itemtd.value]))]):(item[itemtd.value] >= item[itemtd.value+'_MIN'] && item[itemtd.value] <= item[itemtd.value+'_MAX'] )?_c('span',[_vm._v(_vm._s(item[itemtd.value]))]):_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item[itemtd.value]))])])])}),0)}),0)]}}]),model:{value:(_vm.tableRaw),callback:function ($$v) {_vm.tableRaw=$$v},expression:"tableRaw"}}),(_vm.modeSelected == 'Continuous')?_c('p',{staticStyle:{"font-size":"0.90em","text-align":"left"}},[_c('b',[_vm._v(" Note: Red text indicated that value has exceeded above maximum & minimum value ")])]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }